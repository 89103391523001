import * as styles from "../../pages/index.module.css";
// React
import React, { useEffect, useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { PhoneIcon } from "@chakra-ui/icons";

//Chakra UI
import {
  Input,
  Textarea,
  Button,
  useToast,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

export default function ContactForm() {
  var toast = useToast();

  const [state, handleSubmit] = useForm("xbjeloyb");

  if (state.succeeded) {
    toast({
      status: "success",
      title: "Message was sent!",
    });
  }

  //initial values + setting new ones by typing in input
  const [formValues, setFormValues] = useState({
    fullName: "",
    phone: "",
    email: "",
    message: "",
  });
  //error fields
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (input: any) => (e: any) => {
    const value = e.currentTarget.value;

    setFormValues((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  // //form validation
  // const handleFormData = (event: React.FormEvent<HTMLFormElement>) => {
  //   // const [state, handleSubmit] = useForm("xbjeloyb");
  //   event.preventDefault();

  //   setFormErrors(validate(formValues));
  //   setIsSubmit(true);
  // };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // useForm("xbjeloyb")
    }
  }, [formErrors]);

  const validate = (values: any) => {
    type errorType = {
      fullName?: string;
      message?: string;
    };
  };

  return (
    <form className={styles.contactContent} onSubmit={handleSubmit}>
      <div className={styles.contactForm}>
        <div className={styles.contactFormInputs}>
          <div className={styles.formTitle}>
            <div className={styles.display4}>Contact Us</div>
          </div>
          <div className={styles.inputFieldWIthLabel}>
            <div className={styles.inputLabel}>
              <div className={styles.fullName}>Full Name</div>
            </div>
            <Input
              className={styles.input}
              variant="outline"
              size="lg"
              placeholder="John Doe"
              id="fullName"
              name="fullName"
              type="text"
              required
            />
            <ValidationError
              prefix="fullName"
              field="fullName"
              errors={state.errors}
            />
          </div>
          <div className={styles.inputFieldWIthLabel}>
            <div className={styles.inputLabel}>
              <div className={styles.fullName}>Phone Number</div>
            </div>
            <InputGroup className={styles.input}>
              <InputLeftElement
                pointerEvents="none"
                children={<PhoneIcon color="gray.300" />}
              />
              <Input
                id="phone"
                name="phone"
                variant="outline"
                size="lg"
                placeholder="00 38 69 123 456 789"
                type="number"
                required
                defaultValue={formValues.phone}
                onChange={handleChange("phone")}
              />
            </InputGroup>
          </div>
          <div className={styles.inputFieldWIthLabel}>
            <div className={styles.inputLabel}>
              <div className={styles.fullName}>Email</div>
            </div>
            <Input
              id="email"
              type="email"
              name="email"
              className={styles.input}
              variant="outline"
              size="lg"
              placeholder="johndoe@email.com"
              required
              defaultValue={formValues.email}
              onChange={handleChange("email")}
            />
            <div className={styles.errorMsg}>
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            </div>
          </div>
          <div className={styles.inputFieldWIthLabel}>
            <div className={styles.inputLabel}>
              <div className={styles.fullName}>Message</div>
            </div>
            <Textarea
              id="message"
              name="message"
              className={styles.input3}
              variant="outline"
              size="lg"
              placeholder="Text Area"
              required
              defaultValue={formValues.message}
              onChange={handleChange("message")}
            />
            <ValidationError
              prefix="Message"
              field="Message"
              errors={state.errors}
            />
          </div>
          <div className="g-recaptcha" data-sitekey="6LelpAQkAAAAACokgKqQ8IHArRFYGDIxjK2PVdQB"><ValidationError
              prefix="Message"
              field="Message"
              errors={state.errors}
            /></div> 
          <Button
            className={styles.button3}
            variant="solid"
            colorScheme="canadianred"
            type="submit"
            mt="2"
            disabled={state.submitting}
          >
            Send Message
          </Button>
        </div>
      </div>
    </form>
  );
}
