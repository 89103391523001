import * as React from "react";
import { FunctionComponent, useState, useCallback } from "react";
import { Button, Link } from "@chakra-ui/react";
import { navigate } from "gatsby";
import * as styles from "./index.module.css";
import Layout from "../components/Base/Layout";
import ContactForm from "../components/Contact/ContactForm";
import useAuth from "../components/useAuth";
import { SEO } from "../components/Base/Seo";

const Home: FunctionComponent = () => {
  const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);

  const { loggedIn, user } = useAuth();

  const onButton4Click = useCallback(() => {
    navigate("/log-in");
  }, []);
  const onButtonClick = useCallback(() => {
    navigate('/shop');
  }, []);

  const onButton2Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactSectionContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  // const onButton3Click = useCallback(() => {
  //   navigate("/shop");
  // }, []);

  return (
    <Layout>
      <div className={styles.home}>
        <div className={styles.heroSection}>
          <div className={styles.heroContent}>
            <div className={styles.heroText}>
              <div className={styles.title}>
                <div className={styles.display}>Welcome to The Canadian Supply</div>
              </div>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We are committed to providing top-quality products and
                  excellent customer service to meet the needs of healthcare
                  professionals and individuals alike.
                </div>
              </div>
            </div>
            <div className={styles.heroActions}>
            <Link href="/about-us" _hover={{ textDecoration:"none"}}> 
              <Button
                variant="outline"
                colorScheme="canadianred.50"
                size="lg"
                _hover={{bg: "white", color: "black"}}
              >
                About Us
              </Button>
             </Link> 
             <Link href="/shop" _hover={{ textDecoration:"none"}}>
              <Button
                variant="solid"
                colorScheme="canadianwhite"
                color="canadianred.900"
                size="lg"
              >
                Start Shopping
              </Button>
             </Link> 
            </div>
          </div>
        </div>
        <div className={styles.cardsSection}>
          <div className={styles.sectionTitle}>
            <h2 className={styles.display1}>What We Offer</h2>
          </div>
          <div className={styles.cardsRow}>
            <div className={styles.cardSlot}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.title}>
                    <b className={styles.label}>
                      A one stop solution for all your medical needs
                    </b>
                  </div>
                  <Link href="/about-us" _hover={{ textDecoration:"none"}}> 
                  <Button variant="solid" colorScheme="canadianred">
                    Learn More
                  </Button>
                  </Link>
                </div>
                <div className={styles.cardImg}>
                  <img
                    className={styles.depositphotos349498906XL1Icon}
                    alt=""
                    src="/depositphotos-349498906-xl-1@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardSlot1}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.title}>
                    <b className={styles.label}>Wholesale Medical Equipement</b>
                  </div>
                  <Link href="/about-us" _hover={{ textDecoration:"none"}}>
                  <Button variant="solid" colorScheme="canadianred">
                    Learn More
                  </Button>
                  </Link>
                </div>
                <div className={styles.cardImg1}>
                  <img
                    className={styles.depositphotos349498906XL1Icon1}
                    alt=""
                    src="/depositphotos-349498906-xl-11@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.categoriesSection}>
          <div className={styles.sectionTitle}>
            <div className={styles.display2}>Product Categories</div>
          </div>
          <div className={styles.categoryCards}>
            <div className={styles.catCardRow}>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="/image1@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div
                      className={styles.label2}
                    >{`Clinic & Private Practice`}</div>
                    <Button
                      className={styles.button3}
                      variant="solid"
                      colorScheme="canadianred"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="/image2@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>Secondary Items</div>
                    <Button
                      className={styles.button3}
                      variant="solid"
                      colorScheme="canadianred"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="/image3@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>Clinic Instruments</div>
                    <Button
                      className={styles.button3}
                      variant="solid"
                      colorScheme="canadianred"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.catCardRow}>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="/image4@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>
                      Personal Protective Instruments
                    </div>
                    <Button
                      className={styles.button3}
                      variant="solid"
                      colorScheme="canadianred"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="/image5@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>Home Careline</div>
                    <Button
                      className={styles.button3}
                      variant="solid"
                      colorScheme="canadianred"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon1}
                    alt=""
                    src="/image6@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>Special Orders</div>
                    <Button
                      className={styles.button3}
                      variant="solid"
                      colorScheme="canadianred"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whySection}>
          <div className={styles.whyContent}>
            <div className={styles.whyUsImg}>
              <div className={styles.img} />
            </div>
            <div className={styles.features}>
              <div className={styles.sectionTitle2}>
                <h2 className={styles.display3}>Why Us</h2>
              </div>
              <div className={styles.featuresRow}>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/medicinesyrup.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Large Product Range</div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/premiumrights.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Low Prices</div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/ship.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Fast Delivery</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featuresRow}>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/shieldcheck.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Trusted Brands</div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/headset.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Customer Service</div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/world.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Global Reach</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.contactSection}
          data-scroll-to="contactSectionContainer"
          id="contactSectionContainer"
        >
          <ContactForm />
          <div className={styles.contactDetails}>
            <div className={styles.title1}>
              <div className={styles.display5}>Need Anything?</div>
            </div>
            <div className={styles.visibleDetails}>
              <div className={styles.details}>
                <div className={styles.label8}>Call us:</div>
                <b className={styles.label15}><Link href="tel:18882702168">+1-888-270-2168</Link></b>
              </div>
              {/* <div className={styles.details}>
                  <div className={styles.label8}>Email us:</div>
                  <b className={styles.label15}>info@website.net</b>
                </div> */}
              <div className={styles.details}>
                <div className={styles.label8}>Monday to Friday</div>
                <b className={styles.label15}>9AM - 5PM EST</b>
              </div>
            </div>
            <div className={styles.signInBtn}>
              {!loggedIn ? (
                <Button
                  className={styles.button3}
                  variant="solid"
                  colorScheme="canadianred"
                  onClick={onButton4Click}
                >
                  Sign In
                </Button>
              ) : (
                <Button
                  className={styles.button3}
                  variant="solid"
                  colorScheme="canadianred"
                >
                  <Link
                    href="/acc-settings"
                    _hover={{ textDecoration: "none" }}
                  >
                    {user?.firstName == null ? "Customer" : user.firstName}
                  </Link>
                </Button>
              )}
            </div>
            <div className={styles.label20}>
              <div className={styles.label21}>
                {!loggedIn ? (
                  <div className={styles.labelDiv9}>
                    Please sign in for address
                  </div>
                ) : (
                  <div className={styles.labelDiv9}>Canadian Supply</div>
                )}
              </div>
            </div>
            <div className={styles.ccs}>
              <img className={styles.visaIcon} alt="" src="/visa6.svg" />
              <img className={styles.visaIcon} alt="" src="/mastercard6.svg" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const Head = () => (
  <SEO title="Canadian Supply" description="home" />
)